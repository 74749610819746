import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

import store from "./redux/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Guest/Login";
import Register from "./Guest/Register";
import Rregister from "./Guest/Rregister";
import ForgetPassword from "./Authenticated/ForgetPassword";

import NotFound from "./Guest/NotFound";
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from "./Guest/ProtectedRoute";
import AuthenticatedHome from "./Common/AuthenticatedHome";
// import Dashboard from "./Authenticated/Dashboard";

import Profile from "./Authenticated/Profile";
import GuestHome from "./Guest/GuestHome";
import TermsandConditions from "./Guest/TermsandConditions";
import ChangePass from "./Authenticated/ChangePass";
import Faqs from "./Guest/Faqs";
import Success from "./Guest/Success";
import ActivateUser from "./Guest/ActivateUser";

import Services from "./Guest/Services";
import AboutUs from "./Guest/AboutUs";
import Producta from "./Products/Producta";
import Productb from "./Products/Productb";
import Productc from "./Products/Productc";
import Productd from "./Products/Productd";
import LoanRequestForm from "./Authenticated/LoanRequestForm";
import ToBeAmember from "./Guest/ToBeAmember";
import ContactUs from "./Guest/ContactUs";
import WhyAmigos from "./Guest/WhyAmigos";
import QrGenerator from "./Guest/QrGenerator";


const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <AuthenticatedHome />
                </ProtectedRoute>
              }
            />

            <Route path="/dashboard" element={<AuthenticatedHome />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/forgotpassword" element={<ForgetPassword />} />
            <Route path="/changepass" element={<ChangePass />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/whyamigos" element={<WhyAmigos />} />
            

            {/* Guests  */}
            <Route path="*" element={<NotFound />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<GuestHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/services" element={<Services />} />
            <Route path="/ghome" element={<GuestHome />} />
            <Route path="/termsandconditions" element={<TermsandConditions />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/success" element={<Success />} />
            <Route path="/activate/:uid/:token" element={<ActivateUser />} />
            <Route path="/loanrequest" element={<LoanRequestForm />} />
            <Route path="/qrgenerator" element={<QrGenerator />} />

            {/* Products   */}
            <Route path="/producta" element={<Producta />} />
            <Route path="/productb" element={<Productb />} />
            <Route path="/productc" element={<Productc />} />
            <Route path="/productd" element={<Productd />} />
            <Route path="/productd" element={<Productd />} />

            <Route path="/rregister/:loanId" element={<Rregister />} />
            <Route path="/tobeamember" element={<ToBeAmember />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
