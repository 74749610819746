import React from 'react'

function Success() {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white shadow-lg rounded-2xl p-6 md:p-10 text-center max-w-md">
                <svg
                    className="w-16 h-16 text-green-500 mx-auto"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                    ></path>
                </svg>
                <h2 className="text-2xl font-semibold text-gray-800 mt-4">Successfully Registered!</h2>
                <p className="text-gray-600 mt-2">
                    Please check your email and click on the verification link to activate your account.
                </p>
                <a
                    href="/login"
                    className="mt-6 inline-block px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow-md transition"
                >
                    Go to Login
                </a>
            </div>
        </div>
    )
}

export default Success