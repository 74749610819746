import axios from 'axios';
import React, {useEffect, useState} from 'react'
import { Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axiosInstance from '../axiosInstance';
import { FaEye, FaFileAlt } from 'react-icons/fa';

function AlpaRequitemets() {
    const { data } = useSelector((store) => store.customer);
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);

    const [isPaidForSelf, setIsPaidForSelf] = useState(false);
    
    const [targetRecord, setTargetRecord] = useState({})
    const [paymentNote, setPaymentNote] = useState('');
    
    const [transactionNumber, setTransactionNumber] = useState('');
    const [dateOfTransaction, setdateOfTransaction] = useState('');
    const [myFiles, setMyFiles] = useState([]);
    const [banks, setBanks] = useState([]);

    const [bankAcc, setBankAcc] = useState('');

    useEffect(()=>{
        getAllBanks();
        getAllUplaodedFiles()
    },[]);

    const getAllBanks = () => {
        axiosInstance.get(`https://back.amigoslps.com/ddlbanks/ddlbanks`).then((res)=>{
            console.log(res.data)
            setBanks(res.data);
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getAllUplaodedFiles = () =>{
        axiosInstance.get(`https://back.amigoslps.com/ssfiles/paymentbyuserid/${data?.user?.id}`).then((res)=>{
            setMyFiles(res.data);
            console.log(res.data)
        }).catch((err)=>{
            console.log(err)
        })
        
    }

    function handleUpload() {
        if (!file) {
          console.log("first")
          return;
        }
    
        const fd = new FormData();
        fd.append('file', file);
        fd.append('fileUrl', file);
        fd.append('fileType', file.type);
    
        fd.append('isUploaded', true);
        fd.append('amDesc', transactionNumber);
        fd.append('user', data?.user?.id);

        fd.append('isPayment', true);

    
        axiosInstance
          .post(`https://back.amigoslps.com/ssfiles/ssfiles`, fd, {
            headers: {
              'Custom-Header': 'value'
            }
          })
          .then(res => {
            console.log(res.data);
            setFile(null);
            getAllUplaodedFiles();
          })
          .catch(err => {
            console.log(err);
          });
      }

  return (
    <div>
          {/* Modal Show Start  */}
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {targetRecord.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
        <div className='p-2'>
            Dear Mr./Mrs. <b><u>{data?.user?.first_name + " " + data?.user?.last_name} </u> </b>, we respectfully request that you kindly settle the membership fees you have undertaken to pay.
        </div>
        {/* <div>
            <ul>
                <li>  ✅ መደበኛ ቁጠባ(compulsory payment) = 13,000  </li>
                <li>  ✅ አክስዮን (Share earned) = 10,000  </li>
                <li>  ✅ የመመዝገብያና የአክስዮን አገልግሎት ክፍያ(Registration and share service fee) = 6,000  </li>
            </ul>
        </div>

        <div className='mt-2'>
            የአባልነት ሁሉንም ጥቅሞች ለማግኘት ፣ መጀመርያ ከላይ የተዘረዘሩትን ቅድመ ክፍያዎች መክፈል ይኖርብዎታል፡፡
        </div>
        <div className='mt-2'>
            ከላይ የተዘረዘሩትን ቅድመ ክፍያዎች ሲያሟሉ ፣ የሚከተለውን ፎርም በመሙላት ይላኩልን፡፡ እናመሰግናለን፡፡
        </div> */}

        {/* <Card className='mt-4'>
  <Card.Header>የክፍያ ፋይሎች  </Card.Header>
  <Card.Body> 
    እባክዎ የሚከተሉትን መረጃዎች Upload ያድርጉ 
    <ul>
      <li>1. የመመዝገብያ 29,000 ብር የከፈሉበት ስሊፕ </li>
      <li>2. በዝግ አካውንት በቁጠባ ወይም በሼር መልክ የሚቀመጥ 200ሺ የከፈሉበት ስሊፕ  </li>
    </ul>
  </Card.Body>
</Card> */}

        <Card className='mt-4 mb-4'>
                <Card.Header className='text-center text-bold font-bold'>
                    {/* <div className='text-center text-bold font-bold'> */}
                    Membership Payment Details 
                    {/* </div> */}
                </Card.Header>
                <Card.Body>
                    <div className='text-center bg-orange-300 p-3 rounded-md'>  <ul>
      <li>1. የመመዝገብያ 29,000 ብር የከፈሉበት ስሊፕ </li>
      <li>2. በዝግ አካውንት በቁጠባ ወይም በሼር መልክ የሚቀመጥ 200,000 የከፈሉበት ስሊፕ  </li>
    </ul> 
    </div>

    {/* {isPaidForSelf && ( */}
         <div>
         <label htmlFor="paymentNote" className="font-mono">
             Payment Note 
         </label>
         <input
             id="paymentNote"
             name="paymentNote"
             type="text"
             required
             className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
             placeholder="paymentNote"
             value={paymentNote}
             onChange={(e) => setPaymentNote(e.target.value)}
         />
     </div>
    {/* )} */}

   

                    <div>
                        <label htmlFor="transactionNumber" className="font-mono">
                            Transaction Number <span className='font-bold text-red-500'> * </span> 
                        </label>
                        <input
                            id="transactionNumber"
                            name="transactionNumber"
                            type="text"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="transactionNumber"
                            value={transactionNumber}
                            onChange={(e) => setTransactionNumber(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="dateOfTransaction" className="font-mono">
                            Transaction Date <span className='font-bold text-red-500'> * </span> 
                        </label>
                        <input
                            id="dateOfTransaction"
                            name="dateOfTransaction"
                            type="date"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="dateOfTransaction"
                            value={dateOfTransaction}
                            onChange={(e) => setdateOfTransaction(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="form-label">Select Bank <span className='font-bold text-red-500'> * </span>  </label>
                        <select className="form-control" onChange={e => {
                            setBankAcc(banks.find(bank => bank.id == e.target.value))
                        }} >
                        <option value="0">  ባንክ ይምረጡ  </option>
                        {banks.map(bank => (
                                        <option key={bank.id} value={bank.id}>
                                            {bank.amName}
                                        </option>
                                    ))}
                        </select>

                       {bankAcc && <div className='flex justify-center font-bold text-2xl'>{bankAcc.markValue}  </div> }
                    </div> 
                    <div className='mt-2'>
                        <label className="form-label">Bank SLIP <span className='font-bold text-red-500'> * </span>  </label>
                        <InputGroup>
                            <input
                                onChange={e => {
                                    setFile(e.target.files[0]);
                                    console.log(e.target.files[0]);
                                  }}
                                type="file"
                                className="form-control"
                            />
                        </InputGroup>
                    </div>
                </Card.Body>
                <Card.Footer className='flex justify-end'>
                    <Button variant="outline-primary" onClick={() => handleUpload()} >
                        UPLOAD 
                    </Button>
                </Card.Footer>
            </Card>


            {myFiles?.length > 0 &&    
            <Table striped bordered hover className='mt-2'>
            <thead>
              <tr>
              <th> Transaction Number  </th>
              <th> እይ </th>
              </tr>
            </thead>
            <tbody>
{myFiles.map((file,index) => {
  return <tr key={index}>
    <td>
    {file.amDesc}
    </td>
    <td className='flex justify-between'>
    <div>
    {file.fileType === 'application/pdf' ? (
        <a target="_blank" href={file.fileUrl} rel="noopener noreferrer">
            <FaFileAlt />
        </a>
        ) : (
        <FaEye
            onClick={() => {
            setTargetRecord(file);
            setShow(true);
            }}
        />
        )}
    </div>
    </td>
  
  </tr>
})}
            </tbody>
            </Table>}
    </div>
  )
}

export default AlpaRequitemets