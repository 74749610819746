import React from 'react'

function LoanRequestForm() {
    return (
        <div className='flex flex-col items-center justify-center h-screen'>
            <div>Unsuccessful request</div>
            <div>
                You need to be approved as a valid member to request a loan
            </div>
            <div>
                please contact
            </div>
        </div>
    )
}

export default LoanRequestForm