import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { custLogin } from "../redux/authSlice";
import Logo from "../50.png";
import Image from "react-bootstrap/Image";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { t } from "i18next";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    console.log("handleLogin object");
    axios
      .post(`https://back.amigoslps.com/lpsauth/login`, {
        username: username,
        password: password,
      })
      .then(function (response) {
        if (response.data.status !== 401) {
          dispatch(custLogin({ responseData: response.data }));
          navigate("/");
        } else {
          toast.error("Check Username & Password");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Check Username & Password");
        console.log("error");
      });
    e.preventDefault();
  };

  return (
    <div className="min-h-[calc(100vh-64px)] flex items-center justify-center  ">
      <ToastContainer />
      <div className="w-full max-w-md">
        <form className="mt-2 space-y-6" onSubmit={handleLogin}>
            <div className="flex flex-col items-center justify-center m-2">
              <Image src={Logo} height="auto" width="100vh" rounded />
              <div className="flex items-center justify-center gap-2 m-2">
                <div className="text-2xl font-bold text-green-900">ALPA</div>

                <div className="text-2xl font-extrabold text-orange-500">SELF</div>
                <div className="text-2xl font-bold text-green-900">SERVICE</div>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <label htmlFor="PhoneNumber" className="font-mono">
                {t("login.phoneNumber")}
                </label>
                <input
                  id="PhoneNumber"
                  name="username"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder={t("login.phoneNumber")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="py-3">
                <label htmlFor="password" className="font-mono">
                {t("login.password")}
                </label>

                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 px-3 py-2"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Log In
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-700 dark:text-gray-300 cursor-pointer"
                  >
                    Remember me
                  </label>
                </div>
                <div>
                  <Link to="/forgotpassword" className="text-sm text-primary hover:text-primary-hover">
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div className="text-center text-sm text-gray-600 dark:text-gray-400 gap-4">
                <div>Don&apos;t have an account?</div>
                <Link to="/register" className="text-primary hover:text-primary-hover">
                  Register here
                </Link>
              </div>
            </div>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
