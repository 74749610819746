import React from "react";
import LinkItem from "../Reusables/LinkItem";
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <footer className="bg-indigo-50 p-4">
            <div className="container mx-auto px-6 pb-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-lg font-bold text-gray-800 mb-4"> Products </h3>
                        <ul className="space-y-2">
                            <li> <LinkItem path="producta" name="Type A (Product 1)" /> </li>
                            <li> <LinkItem path="productb" name="Type B (Product 2) " /> </li>
                            <li> <LinkItem path="productc" name="Type C (Product 3) " /> </li>
                            <li> <LinkItem path="productd" name="Type D (Product 4)" /> </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-bold text-gray-800 mb-4"> Why Amigos? </h3>
                        <ul className="space-y-2">
                            <li> <LinkItem path="whyamigos" name="Why Amigos" /> </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-bold text-gray-800 mb-4"> Amigos </h3>
                        <ul className="space-y-2">
                            <li> <LinkItem path="contactus" name="Contact Us" /> </li>
                            <li> <LinkItem path="aboutus" name="About Us" /> </li>
                            <li>
                            <select className="form-control" onChange={(e) => {
                                    i18n.changeLanguage(e.target.value)
                                    window.location.reload(false);
                                } }>
                                    <option selected={i18n.language === "en"} value="en"> English </option>
                                    <option selected={i18n.language === "am"} value="am"> Amharic</option>
                                    <option selected={i18n.language === "fr"} value="fr"> Tigrigna </option>
                                </select>
                            {/* <button
                                className="bg-blue-500 text-white px-4 py-2 mt-4"
                                onClick={() => {
                                    i18n.changeLanguage(i18n.language === "en" ? "fr" : "en")
                                    window.location.reload(false);
                                }} >
                                {i18n.language === "en" ? "Switch to French" : "Switch to English"}
                            </button> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <hr /> */}

            <div className="flex flex-col justify-center items-center py-3 px-3">
                <div>
                © 2025 Copyrights by Amigos SACCO.
                 </div> 
                <div>
                All Rights Reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
