import React, { useEffect, useState } from 'react'
import AdvancedSaving from '../Savings/AdvancedSaving'
import RepaymentPeriod from "../Savings/RepaymentPeriod"
import calculateAmortization from './Amortization'

function Advanced() {
  const [loanAmount, setLoanAmount] = useState(0)

    const [lstOptions, setLstOptions] = useState([])
    const [objLoanType, setObjLoanType] = useState({})
    const [objRepayPeriod, setObjRepayPeriod] = useState({})

    const [amountSavingRequired, setAmountSavingRequired] = useState(0)
    const [expectedMonthlyPay, setExpectedMonthlyPay] = useState(0)

    useEffect(() => {
        // let amortization = calculateAmortization(loanAmount,   objRepayPeriod.interest , objRepayPeriod.years)

        setAmountSavingRequired(0.35 * loanAmount)
        // setExpectedMonthlyPay(amortization)
    }, [ objRepayPeriod, loanAmount])

    

  return (
    <div>
     
        <div>
      
        <div>
        <label
          htmlFor="LoanType"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Loan Type
        </label>
        <select
          id="LoanType"
          onChange={(e) => {
            const durations = AdvancedSaving.find(
              x => x.id == Number(e.target.value)
            )?.durations;
            setLstOptions(" Select Loan Type")
            setLstOptions(durations)
            console.log(durations)
          }}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="">Choose a Loan</option>
          {AdvancedSaving.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>


        <div >
              <label> Loan Options </label>
              <select
                onChange={(e) => {
                    const selectedObj = lstOptions.find(x => x.id == Number(e.target.value))
                    console.log("Loan Options")
                    console.log(selectedObj)
                    setObjLoanType(selectedObj)
                    
                    }}
                className="form-control"
              >
                <option value="0"> Select Loan Option </option>
                {lstOptions.length > 0 &&
                  lstOptions.map(opts => {
                    return (
                      <option key={opts.id} value={opts.id}>
                        {`${opts.name}  upto ${opts.toAmount}`}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div >
              <label> Repayment Period </label>
              <select
                onChange={(e) => {
                  const selectedObj = RepaymentPeriod.find(x => x.id == Number(e.target.value))
                  setObjRepayPeriod(selectedObj)
                  console.log(selectedObj)
                }}
                className="form-control"
              >
                <option  value="0"> Select Repayment Period </option>
                {RepaymentPeriod.length > 0 &&
                  RepaymentPeriod.map(rp => {
                    return (
                      <option key={rp.id} value={rp.id}>
                        {`${rp.name}  Interest =>  ${rp.interest}% `}
                      </option>
                    );
                  })}
              </select>
            </div>

        </div>
        <div>
              <label
                htmlFor="LoanAmount"
                className="block text-sm font-medium  mb-1"
              >
                Loan Amount
              </label>
              <input
              min={objRepayPeriod?.fromAmount}
              max={objRepayPeriod?.toAmount}
                id="LoanAmount"
                disabled={!objRepayPeriod}
                type="number"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
                required
              />
            </div>
        </div>

        <div className='bg-white p-2 rounded-md mt-2'>
            <div>
            Saving Required: {amountSavingRequired?.toLocaleString()}
            </div>
            <div>
            Repayment Period: {objRepayPeriod?.months} Months
            </div>
            <div>
            Monthly Pay: {expectedMonthlyPay?.toLocaleString()}
            </div>
        </div>
    </div>
  )
}

export default Advanced