function calculateAmortization(loanAmount, annualRate, years) {
    let monthlyRate = annualRate / 100 / 12;
    let months = years * 12;
    
    // Monthly payment formula
    let monthlyPayment = (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -months));
    let balance = loanAmount;
    let amortizationSchedule = [];
    let totalInterest = 0;

    for (let i = 1; i <= months; i++) {
        let interestPayment = balance * monthlyRate;
        let principalPayment = monthlyPayment - interestPayment;
        balance -= principalPayment;
        totalInterest += interestPayment;

        amortizationSchedule.push({
            month: i,
            principalPayment: principalPayment.toFixed(2),
            interestPayment: interestPayment.toFixed(2),
            remainingBalance: balance.toFixed(2)
        });
    }

    return {
        monthlyPayment: monthlyPayment.toFixed(2),
        totalInterest: totalInterest.toFixed(2),
        amortizationSchedule
    };
}

export default calculateAmortization;