import React from 'react'

function ToBeAmember() {
  return (
    <div className='w-full'>
   
    <h1 className="text-3xl font-bold text-center mb-6">User Registration & Group Claiming Workflow</h1>
    
    <div className="space-y-6">
      <section>
        <h2 className="text-2xl font-semibold">Step 1: User Registration & Group Claiming</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>User visits the website and fills out a <strong>registration form</strong> with details (name, email, phone, password).</li>
          <li>User claims a <strong>group</strong> by providing necessary information (group name, description, documents if required).</li>
          <li>System saves the user and group details as <strong>pending verification</strong>.</li>
          <li>A <strong>verification link</strong> is sent to the user’s email.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Step 2: Email Verification</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>User clicks the verification link in their email.</li>
          <li>The system verifies the token and marks the user's email as <strong>verified</strong>.</li>
          <li>User gains access to a dashboard but cannot proceed until the group claim is approved.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Step 3: Officer Reviews the Claimed Group</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>An officer in the office <strong>logs into an admin panel</strong>.</li>
          <li>They review the <strong>claimed group details</strong> (name, description, attached documents).</li>
          <li>The officer <strong>approves or declines</strong> the group:
            <ul className="list-disc ml-6 mt-2">
              <li><strong>If approved:</strong> The user is notified and gains access to services.</li>
              <li><strong>If declined:</strong> The user is notified with reasons and may be asked to provide additional details.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Step 4: Service Selection & Payment</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>Approved users see a <strong>list of available services</strong> with expected payments.</li>
          <li>User selects the desired service and is shown the <strong>payment instructions</strong> (bank details, reference number).</li>
          <li>User makes the payment and uploads the <strong>bank slip as proof</strong>.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Step 5: Payment Verification by Officer</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>An officer reviews the uploaded <strong>bank slip</strong>.</li>
          <li>The officer verifies the payment against the bank records.</li>
          <li>The officer <strong>marks the payment as verified or rejected</strong>:
            <ul className="list-disc ml-6 mt-2">
              <li><strong>If verified:</strong> The user is notified and connected to the core system.</li>
              <li><strong>If rejected:</strong> The user is notified and may need to re-upload proof.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Step 6: Connecting User to the Core System</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>After successful payment verification, the system <strong>sends the user details to the core system</strong> via API or manual process.</li>
          <li>The user receives <strong>access credentials</strong> or <strong>further instructions</strong> from the core system.</li>
          <li>User can now fully utilize the services.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold">Additional Considerations</h2>
        <ul className="list-disc ml-6 mt-2">
          <li>Implement <strong>email notifications</strong> at each step.</li>
          <li>Provide a <strong>dashboard for users</strong> to track their registration, approval, and payment status.</li>
          <li>Secure <strong>role-based access control (RBAC)</strong> for admin and officers.</li>
          <li>Ensure <strong>audit logging</strong> for all approvals and payment verifications.</li>
        </ul>
      </section>
    </div>
  {/* <div className='w-1/3'>
  <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>REQUIREMENTS BASED ON GROUPS</div>
  <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>ALPA</div>
  <div class="w-full">
  <h2 className="text-orange-600 font-bold text-xl mb-4">Registration</h2>
      <table className="w-full">
        <tbody>
          {[
            ["Share", "10,000"],
            ["Service Charge", "1,000"],
            ["Registration fee", "5,000"],
            ["Compulsory saving", "13,000"],
            ["Total", "29,000"],
          ].map(([name, amount], index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{name}</td>
              <td className="py-2 font-bold">{amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
  <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>ALMEDA</div>
  <div class="w-full">
  <h2 className="text-orange-600 font-bold text-xl mb-4">Registration</h2>
      <table className="w-full">
        <tbody>
          {[
            ["Share", "10,000"],
            ["Service Charge", "1,000"],
            ["Registration fee", "1,000"],
            ["Compulsory saving", "13,000"],
            ["Total", "25,000"],
          ].map(([name, amount], index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{name}</td>
              <td className="py-2 font-bold">{amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
  <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>REGULAR</div>
  <div class="w-full">
  <h2 className="text-orange-600 font-bold text-xl mb-4">Registration</h2>
      <table className="w-full">
        <tbody>
          {[
            ["Share", "10,000"],
            ["Service Charge", "1,000"],
            ["Registration fee", "1,000"],
            ["Compulsory saving", "13,000"],
            ["Total", "25,000"],
          ].map(([name, amount], index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{name}</td>
              <td className="py-2 font-bold">{amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>

  </div> */}
  </div>
  )
}

export default ToBeAmember