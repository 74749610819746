import React from 'react'

function Welcome() {
  return (
    <div>
        <div className='text-center text-bold font-bold text-2xl mb-2'>
        Welcome to Amigos! 🎉
      </div>

    </div>
  )
}

export default Welcome