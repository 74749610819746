import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";

function ChangePass() {
    //   const [t, i18n] = useTranslation("global");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const { data } = useSelector((store) => store.customer);

    const headers = {
        'Fineract-Platform-Tenantid': 'default',
        'tenantIdentifier': 'default',
        'Content-Type': 'application/json',
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your logic to handle password change here
        console.log(currentPassword, newPassword, confirmNewPassword);

        axios.put(`https://mobile:test123@196.189.119.44:8443/fineract-provider/api/v1/users/${data.userId}`, {
            password: newPassword,
            repeatPassword: newPassword
        }, { headers }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })

    };

    return (
        <div className='flex flex-col items-center justify-center h-screen'>
            <Card >
                <Card.Header> Change Password  </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="currentPassword">
                            <Form.Label> Current Password </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Current Password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="confirmNewPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </Form.Group>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="primary" type="submit">
                                Change Password
                            </Button>
                        </div>
                    </Form>
                </Card.Body>

            </Card>

        </div>
    );
}

export default ChangePass;
