import React, { useState } from 'react';


const faqData = [
  {
    question: 'በአንድ ግዜ ስንት የብድር ጥያቄ ማቅረብ እችላለው?',
    answer: 'በአንድ ግዜ ማቅረብ የሚቻለው የብድር ጥያቄ አንድ ሲሆን ፣ የተጠየቀውን የብድር ጥያቄ መልስ ሳያገኝ ሌላ ጥያቄ ማቅረብ የተከለከለ ነው፡፡',
  },
  {
    question: 'ጥያቄዬ ቶሎ ካልተመለሰ ምን ማድረግ እችላለው?',
    answer: 'በሚከተሉት ስልክ ቁጥሮች የአሚጎስ ስታፍ ማግኘት ይቻላል፡፡ ኮል ሴንተር ፡ 8511 ፤ ስልክ +251-111267657 (+251-930086830)',
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full py-4 text-left"
      >
        <span className="font-semibold">{question}</span>
        <span>{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && (
        <div className="px-4 pb-4 text-gray-700">
          {answer}
        </div>
      )}
    </div>
  );
};

const Faqs = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-bold text-orange-500 mb-8 text-center">በተደጋጋሚ የሚጠየቁ ጥያቄዎች</h1>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};



export default Faqs