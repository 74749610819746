import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./50.png";

// import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { custLogout } from "./redux/authSlice";
import { LuLogOut } from "react-icons/lu";
import { FaUserAlt } from "react-icons/fa";
import { t } from "i18next";

function AuthenticatedNav() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { data } = useSelector((store) => store.customer);
    const { isAuthenticated } = useSelector((store) => store.customer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
        <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-2xl font-bold text-orange-400">
                {/* Replace with your actual logo */}
                AMIGOS
                {/* <img src={Logo} width="150vh" height="150vh" alt="logo" /> */}
              </Link>
            </div>
          </div>

          {/* Desktop menu */}
          <div className="hidden sm:flex sm:items-center justify-center">
            {isAuthenticated ? (
              <ul className="flex justify-center text-xl gap-3">
              <FaUserAlt size={24} />
              {data.solomon}
              <NavDropdown
                title={<span>{data?.user?.username}</span>}
                id="navbarScrollingDropdown"
                style={{ fontWeight: 'bold' }}
              >
                <NavDropdown.Item href="/profile"> PROFILE </NavDropdown.Item>
                <NavDropdown.Item href="/changepass">{t('auth.changepassword')}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  onClick={() => {
                    dispatch(custLogout());
                    navigate('/login');
                  }}
                >
                  Lgout <LuLogOut />
                </NavDropdown.Item>
              </NavDropdown>
            </ul>
            ) : (
              <div className="flex space-x-4">
                <Link
                  to="/login"
                  className="text-sm font-medium text-orange-400 hover:text-orange-400"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="text-sm font-medium text-white bg-orange-400 hover:bg-indigo-700 px-3 py-2 rounded-md"
                >
                  Register
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-400"
            >
              <span className="sr-only">Open main menu</span>
              {/* Hamburger icon */}
              <svg
                className={`${isMobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/* Close icon */}
              <svg
                className={`${isMobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} sm:hidden`}>
        <div className="pt-2 pb-3 space-y-1">
          {isAuthenticated ? (
            <>
            {/* <button
            onClick={() => {
              dispatch(custLogout());
              navigate('/login');
            }}
              className="block w-full text-left px-4 py-2 text-base font-medium text-orange-400 hover:text-orange-400 hover:bg-gray-50"
            >
              Logout
            </button> */}
            <ul className="flex justify-center text-xl gap-3">
            <FaUserAlt size={24} />
            {data.solomon}
            <NavDropdown
              title={<span> {data?.user?.username} </span>}
              id="navbarScrollingDropdown"
              style={{ fontWeight: 'bold' }}
            >
              <NavDropdown.Item href="/profile"> PROFILE </NavDropdown.Item>
              <NavDropdown.Item href="/changepass">{t('auth.changepassword')}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => {
                  dispatch(custLogout());
                  navigate('/login');
                }}
              >
                Lgout <LuLogOut />
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="block px-4 py-2 text-base font-medium text-orange-400 hover:text-orange-400 hover:bg-gray-50"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="block px-4 py-2 text-base font-medium text-orange-400 hover:text-orange-400 hover:bg-gray-50"
              >
                Register
              </Link>
            </>
          )}
        </div>
      </div>
        </nav>
        <div className="max-w-7xl min-h-screen mx-auto py-6 sm:px-4">
          <div className="px-4 py-6 sm:px-0">
              <Outlet />
          </div>
          </div>
        </>
    );
}

export default AuthenticatedNav;
