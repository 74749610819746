import React from "react";
import { Suspense } from "react";

// import global_en from "./translations/en/global.json";
// import global_am from "./translations/am/global.json";
// import global_ao from "./translations/ao/global.json";
// import global_tg from "./translations/tg/global.json";
// import "react-toastify/dist/ReactToastify.css";

// import i18next from "i18next";
// import { initReactI18next } from "react-i18next";
import AuthenticatedNav from "./AuthenticatedNav";
import Footer from "./Guest/Footer";

import "./i18n";


// i18next.use(initReactI18next).init({
//   interpolation: { escapeValue: false },
//   lng: "am",
//   compatibilityJSON: "v3",
//   resources: {
//     en: {
//       global: global_en,
//     },
//     am: {
//       global: global_am,
//     },
//     ao: {
//       global: global_ao,
//     },
//     tg: {
//       global: global_tg,
//     },
//   },
// });

function App() {
  
  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <AuthenticatedNav />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
