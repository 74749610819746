import React from 'react'

function TermsandConditions() {
  return (
    <div>
      <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'> 
        የአየር መንገድ ሰራተኛ ሆነው የALPA አባል ከሆኑ በአሚጎስ አባል ለመሆን 
      </div>
      <ol>
        <li> 1. የታደሰ ነዋሪነት መታወቅያ (ፓስፖርት የኢትዮጵያ) </li>
        <li> 2. የአባልነት ተቀማጮች ግዴታው የሚወጣ ፣ ማለትም 
          <ul className='list-disc ml-6 mt-2'>
            <li> 13 ሺ መደበኛ ቁጠባ </li>
            <li> 10 ሺ አክስዮን (የ10 እጣዎች) </li>
            <li> 1 ሺ የአክስዮን አገልግሎት ክፍያ </li>
            <li> 5 ሺ የመመዝገብያ ክፍያ መክፈል  </li>
            <li> በዝግ አካውንት በቁጠባ ወይም በሼር መልክ የሚቀመጥ 200ሺ  </li>
          </ul>
        </li>
        <li> 3. የአሚጎስ ህጎችና ደንቦች ለመፈጸም ዝግጁ የሆነ </li>

      </ol>
      </div>
  )
}

export default TermsandConditions