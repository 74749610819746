import React from 'react'
import { useSelector } from 'react-redux';

function AlmedaDashboard() {
    const { data } = useSelector((store) => store.customer);

  return (
    <div>
        {JSON.stringify(data?.profile)}
    </div>
  )
}

export default AlmedaDashboard