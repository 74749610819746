import React, { useEffect, useState } from 'react'
import AmigosLogo from "../alpa.jpg"
import Welcome from '../Common/Welcome'
import { useSelector } from 'react-redux';
import AlpaRequitemets from './AlpaRequitemets';
import { Button, Card, InputGroup, Modal, Table } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import calculateAmortization from '../calculators/Amortization';
import axiosInstance from '../axiosInstance';
import { FaEye, FaFileAlt } from 'react-icons/fa';

const Products = [
 {"id": 1, "name": "Product A", "DepositNeeded": 40, "MaxLoanSize": 3000000, "InterestRate": 9, "waitingTime": 45, "repayPeriod": 5 },
 {"id": 2, "name": "Product B", "DepositNeeded": 45, "MaxLoanSize": 6000000, "InterestRate": 9, "waitingTime": 60, "repayPeriod": 5 },
 {"id": 3, "name": "Product C", "DepositNeeded": 50, "MaxLoanSize": 12000000, "InterestRate": 14, "waitingTime": 90, "repayPeriod": 5 },
]

function AlpaDashboard() {
  const { data } = useSelector((store) => store.customer);
  const [loanAmount, setLoanAmount] = useState(0)
  const [expectedSaving, setExpectedSaving] = useState(0)
  const [selectedObj, setSelectedObj] = useState({})
  const [objAmz, setObjAmz] = useState({})
  const [file, setFile] = useState(null);
  const [allFiles, setAllFiles] = useState([])

  const [show, setShow] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [targetRecord, setTargetRecord] = useState({})
  const [amDesc, setAmDesc] = useState("")


  useEffect(()=>{
    setExpectedSaving(selectedObj.DepositNeeded/100 * loanAmount)
    const amz = calculateAmortization(loanAmount, selectedObj.InterestRate, selectedObj.repayPeriod)
    console.log(amz)
    setObjAmz(amz)
  },[loanAmount])


  useEffect(() => {
    getAllSSFiles();
  }, [])

  
const getAllSSFiles = () =>{
  axiosInstance
  .get(`https://back.amigoslps.com/ssfiles/byuserid/${data?.user?.id}`)
  .then(res => {
    console.log(res.data);
    setAllFiles(res.data);
  })
  .catch(err => {
    console.log(err);
  });
}

  function handleUpload() {
    if (!file) {
      console.log("first")
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileUrl', file);
    fd.append('fileType', file.type);

    fd.append('isUploaded', true);
    fd.append('isPayment', isPayment);
    fd.append('amDesc', amDesc);
    fd.append('user', data?.user?.id);

    axiosInstance
      .post(`https://back.amigoslps.com/ssfiles/ssfiles`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        console.log(res.data);
        setFile(null);
        getAllSSFiles();
      })
      .catch(err => {
        console.log(err);
      });
  }


  return (
    <div>


        {/* Modal Show Start  */}
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {targetRecord.amDesc} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={targetRecord.fileUrl} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>
         
     <div className='mt-4'>
     <Welcome/>
     </div>

      {Object.keys(data.profile).length == 0 && <AlpaRequitemets/>}

      <div className='mt-4 flex justify-center bg-orange-300 p-2 rounded-md mb-2'>
        ALPA Loan Product Menu
      </div>

      <div >
              <label> Loan Product  <span className='font-bold text-red-500'> * </span> </label>
              <select
                onChange={(e) => {
                  const obj = Products.find(x => x.id == Number(e.target.value))
                  setSelectedObj(obj)
                  console.log(obj)
                }}
                className="form-control"
              >
                <option  value=""> Select Loan Product   </option>
                {Products.length > 0 &&
                  Products.map(lnp => {
                    return (
                      <option key={lnp.id} value={lnp.id}>
                        {`${lnp.name} Interest =>  ${lnp.InterestRate}% => Max (` + lnp.MaxLoanSize.toLocaleString() + `) `}
                      </option>
                    );
                  })}
              </select>
            </div>

          {selectedObj && <div>
            <div>
              <label
                htmlFor="LoanAmount"
                className="block text-sm font-medium  mb-1"
              >
                Loan Amount <span className='font-bold text-red-500'> * </span> 
              </label>
              <input
              min={0}
              max={selectedObj?.MaxLoanSize}
                id="LoanAmount"
              
                type="number"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
                required
              />
            </div>

  </div>}

  <Card className='mt-4'>
    <Card.Header>Loan Calculated Results</Card.Header>
    <Card.Body>
    
  <ListGroup as="ol" numbered>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
         <div className="ms-2 me-auto">
          Interest
        </div>
        <div className="text-2xl font-bold">
        {selectedObj?.InterestRate} %
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
       <div className="ms-2 me-auto">
          Waiting Period
        </div>
        <div className="text-2xl font-bold">
        {selectedObj?.waitingTime} Working Days
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          Loan To Saving Ratio
        </div>
        <div className="text-2xl font-bold">
        {selectedObj?.DepositNeeded } % 
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          Expected Saving Amount
        </div>
        <div className="text-2xl font-bold">
        {expectedSaving?.toLocaleString()}  
        </div>
      </ListGroup.Item>

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          MonthlyPay
        </div>
        <div className="text-2xl font-bold">
        {objAmz?.monthlyPayment?.toLocaleString()}  
        </div>
      </ListGroup.Item>

      {/* <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          Total Interest
        </div>
        <div className="text-2xl font-bold">
        {objAmz?.totalInterest?.toLocaleString()}  
        </div>
      </ListGroup.Item> */}

      
    </ListGroup>
    </Card.Body>
  </Card>

<div className='flex flex-col gap-2 mt-4'>
<div>
Are you interested in the above product? If yes, Click the apply button below.
</div>
 <button className='btn btn-primary'> Apply </button>
</div>

<Card className='mt-4'>
  <Card.Header>የተበዳሪ ፋይሎች </Card.Header>
  <Card.Body> 
    እባክዎ የሚከተሉትን መረጃዎች Upload ያድርጉ 
    <ul>
      <li>1. የነዋሪነት መታወቅያ </li>
      <li>2. ስራ ቦታ መታወቅያ </li>
    </ul>
   
  </Card.Body>
</Card>



<div className='mt-2'>

  <div className="border border-gray-500 p-2">
  {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={e => {
                    setIsPayment(e.target.checked);
                  }}
                />
                <label className="form-check-label">Is Payment</label>
              </div> */}
<div className='mt-3'>
              <label
                htmlFor="filetype"
                className="block text-sm font-medium  mb-1"
              >
                የፋይል ስም  <span className='font-bold text-red-500'> * </span> 
              </label>
              <input
                id="filetype"
                type="filetype"
                placeholder='የፋይል ስም '
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                onChange={(e) => setAmDesc(e.target.value)}
                required
              />
             
            </div>


    <InputGroup className='mt-2 mb-2'>
        <input
          onChange={e => {
            setFile(e.target.files[0]);
            console.log(e.target.files[0]);
          }}
          type="file"
          className="form-control"
        />
        <Button variant="outline-primary" onClick={() => handleUpload()}> ጫን </Button>
      </InputGroup>

      </div>

   {allFiles?.length > 0 &&    <Table striped bordered hover className='mt-2'>
            <thead>
              <tr>
              <th> የመረጃ ስም  </th>
              <th> እይ </th>
              {/* <th> አጥፋ </th> */}
              </tr>
            </thead>
            <tbody>
{allFiles.map((file,index) => {
  return <tr key={index}>
    <td>
    {file.amDesc}
    </td>
    <td className='flex justify-between'>
      {/* <div>{file.isPayment ? "PAYMENT" : "DOC"} </div> */}
    <div>
    {file.fileType === 'application/pdf' ? (
                            <a target="_blank" href={file.fileUrl} rel="noopener noreferrer">
                              <FaFileAlt />
                            </a>
                          ) : (
                            <FaEye
                              onClick={() => {
                                setTargetRecord(file);
                                setShow(true);
                              }}
                            />
                          )}
    </div>
    </td>
    {/* <td>
    <button className='btn btn-danger btn-sm'>Delete</button>
    </td> */}
  </tr>
})}
            </tbody>
            </Table>}
</div>


    </div>
  )
}

export default AlpaDashboard