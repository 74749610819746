import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import AlpaDashboard from "../Role_Alpa/AlpaDashboard"
import AlmedaDashboard from '../Role_Almeda/AlmedaDashboard';
import RegularDashboard from '../Role_Regular/RegularDashboard';

function AuthenticatedHome() {
const { data } = useSelector((store) => store.customer);

useEffect(() => {
    console.log(data)
})

  return (
    <React.Fragment>
        <>
          { data?.user?.groups[0] === "ROLE_ALPA" && <AlpaDashboard /> }
          { data?.user?.groups[0] === "ROLE_ALMEDA" && <AlmedaDashboard /> }
          { data?.user?.groups[0] === "ROLE_REGULAR" && <RegularDashboard /> }
        </>
    </React.Fragment>
  );
}

export default AuthenticatedHome