const AdvancedSaving = [
    { "id":  1, "name": "Housing Constraction", "durations": [
        {"id": 1, "fromAmount": 0, "toAmount": 500000, "name": "1 Month", "deposit": 35},
        {"id": 2, "fromAmount": 500001, "toAmount": 1500000, "name": "2 Month", "deposit": 35},
    ] },
    {  "id":  2, "name": "Housing Purchase" , "durations": [
        {"id": 1, "fromAmount": 0, "toAmount": 3000000, "name": "4 Month", "deposit": 45},
        {"id": 2, "fromAmount": 3000001, "toAmount": 6000000, "name": "5 Month", "deposit": 50},
        {"id": 3, "fromAmount": 6000001, "toAmount": 10000000, "name": "6 Month", "deposit": 50},
    ]},
    {  "id":  3, "name": "Working Capital & Personal Business Expansion Loan" , "durations": [
        {"id": 1, "fromAmount": 0, "toAmount": 500000, "name": "1 Month", "deposit": 30},
        {"id": 2, "fromAmount": 500001, "toAmount": 1000000, "name": "2 Month", "deposit": 35},
        {"id": 3, "fromAmount": 1000001, "toAmount": 3000000, "name": "3 Month", "deposit": 40},
        {"id": 4, "fromAmount": 3000001, "toAmount": 6000000, "name": "3 Month", "deposit": 50},
        {"id": 5, "fromAmount": 6000001, "toAmount": 10000000, "name": "6 Month", "deposit": 50},
    ]},
    {  "id":  4, "name": "Vehicle Loan" , "durations": [
        {"id": 1, "fromAmount": 0, "toAmount": 2000000, "name": "1.5 Month", "deposit": 40},
        {"id": 2, "fromAmount": 2000001, "toAmount": 3000000, "name": "2 Month", "deposit": 40},
        {"id": 3, "fromAmount": 3000001, "toAmount": 6000000, "name": "3 Month", "deposit": 45},
    ]},
    {  "id":  5, "name": "Machinery & Equipment Loan" , "durations": [
        {"id": 1, "fromAmount": 0, "toAmount": 3000000, "name": "4 Month", "deposit": 50},
        {"id": 2, "fromAmount": 3000001, "toAmount": 600000, "name": "5 Month", "deposit": 50},
    ]}
]
export default  AdvancedSaving

// [
//     {
//         "name": "Housing Constraction" ,
//         "amName": "Housing Constraction" 
//     },
//     {
//         "name": "Housing Purchase" ,
//         "amName": "Housing Purchase" 
//     },
//     {
//         "name": "Working Capital & Personal Business Expansion Loan" ,
//         "amName": "Working Capital & Personal Business Expansion Loan" 
//     },
//     {
//         "name": "Vehicle Loan" ,
//         "amName": "Vehicle Loan" 
//     },
//     {
//         "name": "Machinery & Equipment Loan" ,
//         "amName": "Machinery & Equipment Loan" 
//     }
// ]