import React from 'react'
import {QRCodeSVG} from 'qrcode.react';


function QrGenerator() {
  return (
    <div>
        <QRCodeSVG value="https://amigoslps.com" />
    </div>
  )
}

export default QrGenerator