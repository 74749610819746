import React from 'react'
import { Link } from 'react-router-dom'

function LinkItem({ path, name }) {
    return (
        <Link to={path} className="text-gray-600 hover:text-blue-500 transition duration-200">
            {name}
        </Link>
    )
}

export default LinkItem