import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PulseLoader } from "react-spinners";


const ActivateUser = () => {
    const [activationSuccesful, setActivationSuccesful] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { uid, token } = useParams()
    const [isSuccessful, setIsSuccessful] = useState(false);

    useEffect(() =>  {
        // const activateUser = async () => {
        //     if (!token) {
        //         setActivationSuccesful(false);
        //         setMessage('Activation token is missing.');
        //         return;
        //     }
        //     try {
        //         const response = await axios.get(`https://back.amigoslps.com/lpsauth/activate/${uid}/${token}`);
        //         if (response.data.message) {
        //             setActivationSuccesful(true);
        //             setMessage(response.data.message);
        //         } else {
        //             setActivationSuccesful(false);
        //             setMessage('Activation failed.');
        //         }
        //     } catch (error) {
        //         setActivationSuccesful(false);
        //         setMessage('Something went wrong. Please try again later.');
        //     }
        // };

        activateUser();

       
        
    }, []);

    const activateUser = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`https://back.amigoslps.com/lpsauth/activate/${uid}/${token}`);
            setIsSuccessful(response)
        } catch (error) {
            setIsSuccessful(false)
        }finally{
            setIsLoading(false)
        }
    }


      if (isLoading) {
        return (
          <div className="h-screen flex items-center justify-center bg-gray-100">
            <div className="flex flex-col gap-3 items-center justify-center">
              <PulseLoader size={30} color="orange" loading="true" />
              <div style={{ color: "#FFA500" }}>  Page Loading ...</div>
            </div>
          </div>
        );
      }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
          
                {isSuccessful ? (
                    <>
                        <h1 className="text-green-500 text-4xl font-bold">Success!</h1>
                        <p className="mt-4 text-2xl text-gray-600">Your account is Activated!</p>
                        <Link to="/login" className="text-blue-500 text-2xl hover:underline">  Go to Login </Link>
                    </>
                ) : (
                    <>
                        <h1 className="text-red-500 text-2xl font-bold">Error</h1>
                        <p className="mt-4 text-gray-600">Something went wrong. Please try again later</p>
                    </>
                )}
           
        </div>
    );
};

export default ActivateUser