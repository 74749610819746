import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import { PulseLoader } from "react-spinners";

function Profile() {
  const { data } = useSelector((store) => store.customer);
  const [user, setUser] = useState([]);
  const [clientreport, setClientReport] = useState([]);

  const [clientType, setClientType] = useState("");
  // const [base64Data, setbase64Data] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setClientType(clientreport?.length > 0 ? clientreport[clientreport?.length - 1] : "");
  }, [clientreport]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    setIsLoading(true);
    try {
      const res_userdata = await axios.get(
        `https://back.amigoslps.com/fineract/userprofile?entityAccountNo=${data?.clients[0]}&base64EncodedAuthenticationKey=${data?.base64EncodedAuthenticationKey}`
      );
      setUser(res_userdata?.data);
      console.log("res_userdata?.data");
      console.log(res_userdata?.data);

      const res_report = await axios.get(
        `https://back.amigoslps.com/fineract/clientreport?entityExternalId=${res_userdata?.data?.externalId}`
      );
      setClientReport(res_report?.data?.data[0]?.row);

      // const res_image = await axios.get(
      //   `https://back.amigoslps.com/fineract/customerimage?imageId=${res_userdata?.data?.imageId}`
      // );
      // console.log(res_image.data);
      // setbase64Data(`data:image/jpeg;base64,${res_image.data}`);

      // Call the function with the Base64 string and desired filename
      // saveBase64AsImageFile(base64Image, "decoded_image.jpg");
      // saveBase64AsImageFile(res_image.data, "decoded_image.jpg");

      // const img = document.createElement("img");
      // img.src = res_image.data;
      // document.body.appendChild(img);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // setbase64Data("");
    } finally {
      setIsLoading(false);
    }
  };

  // function saveBase64AsImageFile(base64, filename) {
  //   // Create a link element
  //   // const link = document.createElement("a");
  //   const link = document.createElement("a");

  //   link.href = base64;
  //   link.download = filename;

  //   // Trigger the download
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>  Page Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center">
      <ListGroup as="ul" style={{ width: "100%", padding: "0", maxWidth: "600px" }}>
        <div className="flex items-center justify-center">
          <div className="relative w-32 h-32 mb-5">
            <img
              src="https://vias.placeholder.com/150"
              className="w-full h-full rounded-full border-4 border-white shadow-lg object-cover"
            />
          </div>
        </div>
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto">External ID</div>
          <div>{user?.externalId}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto">Account Number </div>
          <div>{user?.accountNo}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Office Name </div>
          <div>{user?.officeName}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Full Name </div>
          <div>{user?.displayName}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Phone Number</div>
          <div>{user?.mobileNo}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Date Of Birth (YYYY-MM-DD) </div>
          <div>{user?.dateOfBirth?.join("-")}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Gender </div>
          <div>{user?.gender?.name}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start bg-orange-300">
          <div className="ms-2 me-auto"> Client Type</div>
          <div>{clientType}</div>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto"> Gender </div>
          <div>{user?.gender?.name}</div>
        </ListGroup.Item>

      </ListGroup>
    </div>
  );
}

export default Profile;
