import React from 'react'
import { Link } from 'react-router-dom'
import Advanced from '../calculators/Advanced'
import { t } from 'i18next'
// import Contractual from '../calculators/Contractual'
// import Compulsary from '../calculators/Compulsary'

// import Image1 from "../images/1.png";
// import Image2 from "../images/2.png";
// import Image3 from "../images/3.png";


function GuestHome() {
  return (
    <div className="relative bg-white overflow-hidden">
      <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16 lg:px-8 xl:mt-20">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Welcome to </span>{' '}
            <span className="block text-orange-400 xl:inline">Amigos</span>
          </h1>
         
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
         
            Get started with our application and discover amazing features that will help you achieve your goals.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start gap-3">
            <div className="rounded-md shadow">
              <Link
                to="/register"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-400 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                Get started
              </Link>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link
                to="/login"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
              >
                Sign in
              </Link>
            </div>
          {/* to be a member start  */}
         
          <div className="rounded-md shadow"> 
            <Link
                to="/tobeamember"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-400 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                TO BE A MEMBER
              </Link>
            </div>
          {/* to be a member end  */}
          </div>
        </div>
      </main>

      <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>SERVICES</div>


      {/* Three Content Sections */}
      <div className="bg-white py-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Content Box 1 */}
            <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-orange-400 mb-3">Financial Services</h3>
              <p className="text-gray-700">
                We offer comprehensive financial solutions including savings accounts, loans, and investment opportunities tailored to meet your needs.
              </p>
            </div>

            {/* Content Box 2 */}
            <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-orange-400 mb-3">Member Benefits</h3>
              <p className="text-gray-700">
                Enjoy exclusive member benefits, competitive interest rates, and personalized financial advisory services.
              </p>
            </div>

            {/* Content Box 3 */}
            <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-orange-400 mb-3">Technology-Driven</h3>
              <p className="text-gray-700">
                Experience seamless service delivery through our cutting-edge digital platforms and modern banking solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Vision and Mission Section */}
      <div className="bg-gradient-to-r from-indigo-50 to-white py-8 px-4 sm:px-6 lg:px-8 my-8">
        <div className="max-w-7xl mx-auto">
          <div className="space-y-12">
            {/* Vision */}
            <div className="text-center">
              <h2 className="text-3xl font-bold text-orange-400 mb-4">Our Vision</h2>
              <p className="text-xl text-gray-700">
                Becoming the most preferred financial and non-financial service provider by 2030.
              </p>
            </div>

            {/* Mission */}
            <div className="text-center">
              <h2 className="text-3xl font-bold text-orange-400 mb-4">Our Mission</h2>
              <p className="text-xl text-gray-700 leading-relaxed">
                We aspire to enhance the living standards of our members in Ethiopia, by providing
                financial and non-financial services in short period using up-to date technology
                with qualified, energetic, passionate and committed staff in order-to-maximize
                value to our stakeholders in a socially responsible manner.
              </p>
            </div>

            {/* About Us */}
            <div className="text-center">
                    <h2 className="text-3xl font-bold text-orange-400 mb-4">Amigos</h2>
                    <p className="text-xl text-gray-700">
                    Our organization secures a federal license With 10,000+ members and 5+ billion Birr assets, we’ve achieved 45%+ profits yearly, distributing a 53% dividend for our members in 2016 EC.
                    </p>
                  </div>

                </div>
              </div>
            </div>

      {/* <Alert> SAVING LOANS </Alert> */}
      <div>
      We offer a diverse range of loan options tailored to meet your financial needs, 
      depending on the type of savings plan you choose. Our savings plans include
      <ol>
      
      <li> 
        <b>1. Advanced savings </b> allow for immediate access to funds, where savings act as collateral, offering a flexible borrowing solution. 
      </li>
        <li>  <b>2. Contractual savings</b>, which require a fixed deposit over a set period, 
        ensuring financial discipline while providing access to loans based on accumulated funds.  </li>
        <li> <b>3. Compulsory savings </b> function as a mandatory contribution, 
      typically tied to employment or membership programs, allowing members to build creditworthiness and secure loans with ease. 
      For those needing quick financial assistance,  </li>
      
      </ol>
      </div>

      {/* 

      <div className='pt-4'>
          Each savings type plays a crucial role in determining the loan terms, interest rates, and repayment structures. 
          Contractual savings provide long-term stability, making them ideal for larger loan amounts with lower interest rates. 
          Compulsory savings ensure members have a reliable financial safety net, increasing their borrowing potential over time. 
          Meanwhile, advanced savings cater to urgent financial needs, giving borrowers instant access to credit without extensive waiting periods. 
          By choosing a savings plan that aligns with your financial goals, you can enjoy structured and convenient loan access tailored 
          to your specific requirements.
      </div> 

      */}


<div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'>General Information / Regular Member</div>

<div className="flex flex-wrap">
  <div className="w-full sm:w-1/2 lg:w-1/4">
  <h2 className="text-orange-600 font-bold text-xl mb-4">INTEREST RATE</h2>
      <table className="w-full mb-6">
        <thead>
          <tr className="text-left border-b">
            <th className="py-2">Repayment Period</th>
            <th className="py-2">Rate</th>
          </tr>
        </thead>
        <tbody>
          {[
            ["6 Month", "9%"],
            ["1 Year", "13%"],
            ["2 Years", "14%"],
            ["3 - 5 Years", "15%"],
          ].map(([period, rate], index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{period}</td>
              <td className="py-2">{rate}</td>
            </tr>
          ))}
        </tbody>
      </table>

  </div>
  <div className="w-full sm:w-1/2 lg:w-1/4">
  {/* Guarantee Section */}
  <h2 className="text-orange-600 font-bold text-xl mb-4">Guarantee</h2>
      <ul className="list-disc pl-6 mb-6">
        {["House guarantee", "Vehicle Guarantee", "Banks And Insurance company share", "Members deposit", "Salary Guarantee (15% of net loan)"].map(
          (item, index) => (
            <li key={index} className="py-1">{item}</li>
          )
        )}
      </ul>
  </div>
  <div className="w-full sm:w-1/2 lg:w-1/4">
    {/* Necessary Documents */}
    <h2 className="text-orange-600 font-bold text-xl mb-4">Necessary Documents</h2>
      <ul className="list-disc pl-6 mb-6">
        {["Residents ID", "Marriage/unmarried Certificate", "Bank statement", "Collateral"].map(
          (item, index) => (
            <li key={index} className="py-1">{item}</li>
          )
        )}
      </ul>
  </div>
  <div className="w-full sm:w-1/2 lg:w-1/4">
  <h2 className="text-orange-600 font-bold text-xl mb-4">Registration</h2>
      <table className="w-full">
        <tbody>
          {[
            ["Share", "10,000"],
            ["Service Charge", "1,000"],
            ["Registration fee", "1,000"],
            ["Compulsory saving", "13,000"],
            ["Total", "25,000"],
          ].map(([name, amount], index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{name}</td>
              <td className="py-2 font-bold">{amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
</div>



    <div className='mt-4 p-2 flex justify-center bg-orange-200 rounded-md mb-2'> Let's support you calculating</div>
          {/* Three Content Sections */}
          <div className="bg-white py-2">
            <div className="max-w-7xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Content Box 1 */}
                <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <h3 className="text-xl font-semibold text-orange-400 mb-3">Advanced</h3>
                  <Advanced/>
                </div>

                {/* Content Box 2 */}
                {/* <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <h3 className="text-xl font-semibold text-orange-400 mb-3">Contractual</h3>
                  <Contractual/>
                </div> */}

                {/* Content Box 3 */}
                {/* <div className="bg-indigo-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <h3 className="text-xl font-semibold text-orange-400 mb-3">Compulsary </h3>
                <Compulsary/>
                </div> */}
              </div>
            </div>
          </div>

    </div>
  )
}

export default GuestHome