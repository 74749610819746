import React from "react";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TermsandConditions from "./TermsandConditions";

export default function Register() {
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [userRole, setUserRole] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {

    try {
      e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

      await axios.post(`https://back.amigoslps.com/lpsauth/register`, {
      username: phoneNumber,
      email: username,
      password,
      phoneNumber,
      groups: userRole,
      first_name: first_name,
      last_name: last_name,
    });
    toast.success("User created successfully");
    navigate("/success");
      
    } catch (error) {
      toast.error(error);
      console.log(error);
    }finally{
    }
    
  };

  return (
    <div className="min-h-[calc(100vh-64px)] flex items-center justify-center  ">
      <ToastContainer />
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col gap-4 bg-white shadow-xl rounded-lg px-8 py-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium  mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Format: abcd@gmail.com
              </p>
            </div>

            <div>
              <label
                htmlFor="phonenumber"
                className="block text-sm font-medium  mb-1"
              >
                Phone Number
              </label>
              <input
                id="phonenumber"
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Format: 0913535139
              </p>
            </div>

          
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                Company
              </label>
              <div className="mt-1">
                <select
                required
                onChange={e=>{
                  setUserRole(e.target.value)
                  console.log(e.target.value)
                }}
                  id="role"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select Your Company</option>
                  <option value="ROLE_ALPA">ROLE_ALPA</option>
                  <option value="ROLE_ALMEDA">ROLE_ALMEDA</option>
                  <option value="ROLE_REGULAR">ROLE_REGULAR</option>
                </select>
              
              </div>
            </div>



            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium  mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                placeholder="Create a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium  mb-1"
              >
                Confirm Password
              </label>
              <input
                id="confirm-password"
                type="password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Register
              </button>
            </div>

            <div className="flex items-center">
              <input
                id="terms"
                type="checkbox"
                onChange={e=> setTermsChecked(e.target.checked)}
                required
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="terms" className="ml-2 block text-sm ">
                I agree to the
                <Link
                  to="/termsandconditions"
                  className="text-primary hover:text-primary-hover"
                >
                  Terms and Conditions
                </Link>
              </label>
            </div>

            {termsChecked && <TermsandConditions/>}

            <div className="text-center text-sm text-gray-600 dark:text-gray-400">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-primary hover:text-primary-hover"
              >
                Sign in here
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
